import { useToast } from "@chakra-ui/react";
import { fetcher, LoginWithProviderEndpoint, ConnectWithProviderEndpoint, ReferralsEndpoints, Method } from "@olagg/api-hooks";
import { supabase } from "@olagg/plugins";
import { useAuthStore, useOnboardingStore } from "@olagg/store";
import { analytics } from "../../../analytics";
import Cookies from "js-cookie";
import { useContext, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AdTrackingContext } from '@/contexts/adTrackingContext';
import { useTranslation } from "react-i18next";

const REFER_FRIEND = '1';

const useAuthProviders = () => {
  const { t } = useTranslation();
  const { fcb, fbp } = useContext(AdTrackingContext)
  const redirect = useRef<any>()
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false)
  const { getMe, me } = useAuthStore()
  const [searchParams] = useSearchParams()
  const toast = useToast()
  const { closeOnboarding, setIsRedirected, setIsSignedIn } = useOnboardingStore()
  const referrerId =
    searchParams.get('referrerId') ||
    localStorage.getItem('referrerId')
  const [referredAction, referredId] = referrerId?.split('T') || [];

  const persistUserToken = (token: any) => {
    const cookieOptions = {
      expires: 365,
      domain: import.meta.env.DEV ? 'localhost' : '.olagg.io'
    }
    Cookies.set('auth._token.local', token, cookieOptions)
  }

  const triggerAnalyticsAfterLogin = (provider: string, user?: any) => {
    const currentUser = me || user
    let props = {
      identity_provider: provider,
      user_agent: navigator.userAgent
    }
    if (fcb != null) props.fbc = fcb
    if (fbp != null) props.fbp = fbp
    analytics()
      ?.track('User SignedIn', props)

    analytics()
      ?.identify(`${currentUser.id}`, {
        email: currentUser.email,
        name: currentUser.name,
        google_id: currentUser.authProviderData?.hasOwnProperty('google') ? currentUser.authProviderData.google.id : '',
        discord_id: currentUser.authProviderData?.hasOwnProperty('discord') ? currentUser.authProviderData.discord.id : '',
        game_categories: currentUser.gameCategories,
        opportunities: currentUser?.opportunities,
        level: currentUser.level?.value,
        xp: currentUser.level?.score,
        identity_provider: provider,
        user_agent: navigator.userAgent,
        human_verification_level: currentUser?.verification?.level,
        human_verification_providers: currentUser?.verification?.providers,
      })
  }

  const validateReferFriend = (newAccount: boolean) => {
    // validate only if it's a new account, the action is refer friend and the referred user id was sent
    if (newAccount && referrerId && referredId && referredAction === REFER_FRIEND) {
      fetcher(ReferralsEndpoints.validateAction(referrerId))
        .then(() => localStorage.removeItem('referrerId'))
        .catch((e) => console.log(e))
    };
  }

  const handleRedirect = async (provider: string) => {
    setIsSignedIn(true, provider)
    if (redirect.current) setIsRedirected(true)
  }

  const getUserTokenDiscord = async (accessToken: any) => {
    try {
      await fetcher(LoginWithProviderEndpoint.discord({
        accessToken: accessToken,
        provider: 'discord',
        referrerId: referredAction === REFER_FRIEND ? referredId : undefined,
      })).then(async response => {
        persistUserToken(response?.token);
        validateReferFriend(response?.new_account);
        await getMe().then((user) => {
          if (response?.user?.authProviderData?.discord?.justLinked) {
            toast({
              title: t('Login.loginToasts.linked'),
              colorScheme: 'olaggPink',
              status: 'success',
              description: '',
              position: 'bottom-right'
            })
          } else {
            toast({
              title: t('Login.loginToasts.success'),
              colorScheme: 'olaggPink',
              status: 'success',
              description: '',
              position: 'bottom-right'
            })
            triggerAnalyticsAfterLogin('discord', user)
          }
        })
      })
        .catch((e) => {
          toast({
            title: t('Login.loginToasts.errorOtherUser'),
            status: 'error',
            position: 'top',
            duration: 6000,
            isClosable: true
          })
        })
        .finally(async () => {
          await supabase.auth.signOut()
          setIsLoadingSpinner(false)
          handleRedirect('discord')
        })

    } catch (error: any) {
      console.log(error.message);
    }
  }

  const getUserTokenTwitter = async (accessToken: any) => {
    try {
      await fetcher(ConnectWithProviderEndpoint.twitter({
        access_token: accessToken,
        provider: 'twitter',
      })).then(async response => {
        persistUserToken(response?.token);
        await getMe();
        closeOnboarding();
        toast({
          title: t('Login.loginToasts.linked'),
          colorScheme: 'olaggPink',
          status: 'success',
          description: '',
          position: 'bottom-right'
        })
      })
        .finally(async () => {
          await supabase.auth.signOut()
          setIsLoadingSpinner(false)
          // handleRedirect()
        })

    } catch (error: any) {
      toast({
        title: t('Login.loginToasts.errorOtherUser'),
        status: 'error',
        position: 'top',
        duration: 6000,
        isClosable: true
      })
    }
  }

  const getUserTokenGoogleSupabase = async (accessToken: any) => {
    try {
      await fetcher(LoginWithProviderEndpoint.google({
        accessToken: accessToken,
        referrerId: referredAction === REFER_FRIEND ? referredId : undefined,
        provider: 'google'
      })).then(async response => {
        persistUserToken(response?.token);
        validateReferFriend(response?.new_account);
        await getMe().then((user) => {
          closeOnboarding();
          toast({
            title: t('Login.loginToasts.success'),
            colorScheme: 'olaggPink',
            status: 'success',
            description: '',
            position: 'bottom-right'
          })
          triggerAnalyticsAfterLogin('google', user)
        });
      })
        .finally(async () => {
          await supabase.auth.signOut()
          setIsLoadingSpinner(false)
          handleRedirect('google')
        })

    } catch (error: any) {
      console.log(error.message);
    }
  }

  const initRedirectPath = () => {
    redirect.current = searchParams.get('redirect') || localStorage.getItem('redirect')
    if (redirect.current) {
      localStorage.setItem('redirect', redirect.current)
    }
  }

  const checkAuthStatusSupabase = async () => {
    const { data } = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log('EVENT', event)
      console.log('SESSION', session)
      if (event == 'SIGNED_IN') {
        const isDiscord = session?.user?.user_metadata?.iss.includes('discord');
        const isTwitter = session?.user?.user_metadata?.iss.includes('twitter');
        const isGoogle = session?.user?.user_metadata?.iss.includes('google');
        if (isDiscord) {
          setIsLoadingSpinner(true)
          await getUserTokenDiscord(session?.access_token)
        } else if (isTwitter) {
          setIsLoadingSpinner(true)
          await getUserTokenTwitter(session?.access_token)
        } else if (isGoogle) {
          setIsLoadingSpinner(true)
          await getUserTokenGoogleSupabase(session?.access_token)
        }
        data.subscription.unsubscribe()
      }
    });
  }


  const checkAuthStatusNewsroom = async () => {
    const code = searchParams.get('code')

    if (code) {
      setIsLoadingSpinner(true)
      await fetcher(LoginWithProviderEndpoint.newsroom({
        code, provider: 'newsroom'
      }))
        .then(async response => {
          persistUserToken(response?.token);
          await getMe().then((user) => {
            closeOnboarding();
            toast({
              title: t('Login.loginToasts.success'),
              colorScheme: 'olaggPink',
              status: 'success',
              description: '',
              position: 'bottom-right'
            })
            triggerAnalyticsAfterLogin('newsroom', user)

          })
        })
        .finally(async () => {
          setIsLoadingSpinner(false)
          handleRedirect('newsroom')
        })
    }
  }


  return {
    checkAuthStatusNewsroom,
    checkAuthStatusSupabase,
    initRedirectPath
  }
}

export default useAuthProviders;
